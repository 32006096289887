<template>
    <div class="PurchasesUsed">
        <layout :header="'MY ' + $route.params.type " @goback="$router.push('/Purchases')" :bodyStrong="false">
            <template v-slot:content>
                <v-col cols="12" xs="12" sm="10" md="8" lg="6">
                    <v-row no-gutters justify="center">
                        <v-col cols="12" class="content">
                            <v-row justify="center">
                                <v-col cols="12" class="text-center">
                                    <qr-code :text="couponsCodeById.code" :size="150"></qr-code>
                                    <div style="letter-spacing:3px" class="mt-2">
                                        {{couponsCodeById.code || '' }}
                                    </div>
                                    <div class="px-2 pt-1"
                                        style="font-size:12px;font-weight:800;text-indent;12px;color:#a5a5a5;"
                                        v-if="couponsCodeById.expire_at != undefined">
                                        {{$t('expire')}} {{DateTime(couponsCodeById.expire_at.iso) || ''}}
                                    </div>
                                </v-col>
                                <v-col cols="12" class="px-2 pb-0">
                                    <div class="header-store">
                                        <!-- {{$i18n.locale == 'th' ? couponsCodeById.coupon_id.name || '': couponsCodeById.coupon_id.name_en || ''}} -->
                                        {{couponsCodeById.coupon_list_id.name}}
                                    </div>
                                    <div class="date-store">
                                        {{$i18n.locale == 'th' ? couponsCodeById.coupon_id.title || '': couponsCodeById.coupon_id.title_en || ''}}
                                    </div>
                                </v-col>
                                <v-col cols="12" class="title-store px-2">
                                    {{$i18n.locale == 'th' ? couponsCodeById.coupon_id.detail || '': couponsCodeById.coupon_id.detail_en || ''}}
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </template>
        </layout>
    </div>
</template>

<script>
    import VueQRCodeComponent from 'vue-qrcode-component'
    import Layout from "../../components/layout_1"
    export default {
        name: "PurchasesUsed",
        components: {
            'layout': Layout,
            'qr-code': VueQRCodeComponent
        },
        computed: {
            couponsCodeById() {
                return this.$store.getters.coupon_codesById(this.$route.params.id);
            },
        },
    }
</script>

<style lang="scss">
    .PurchasesUsed {
        height: 100%;

        img {
            margin: 0 auto !important;
        }

        .content {
            padding: 0px 16px 55px 16px;
            min-height: 100%;

            .header-store {
                font-weight: 800;
                font-size: 20px;
                margin-top: 10px;
            }

            .date-store {
                color: #969696;
                font-size: 12px;
                line-height: 1;
            }

            .title-store {
                font-size: 14px;
                color: #969696;
                margin-top: 20px;
            }
        }
    }
</style>